import React, { FC } from 'react'
import { Alert, Box, Grid, TextField, Stack, Card } from '@mui/material'
import { observer } from 'mobx-react'

import AddressEditor from '../../../components/AddressEditor/AddressEditor'
import ImageUploader from '../../../components/ImageUploader/ImageUploader'
import MaskedInput from '../../../components/Form/MaskedInput'
import HaloAvatar from '../../../components/HaloAvatar/HaloAvatar'

import AdminOrganizationProfileControls from './AdminOrganizationProfileControls/AdminOrganizationProfileControls'

import { useRootStore } from '../../../services/RootStoreContext'

import useFormContext from '../../../components/Form/FormContext'

const OrgProfile: FC = (): React.JSX.Element => {
  const { orgStore } = useRootStore()
  const { currentOrg } = orgStore
  const { isOrgAdmin } = currentOrg

  const {
    handleChange,
    handleBlur,
    isFieldTouched,
    getError,
    model: currentOrgState,
  } = useFormContext()

  if (!isOrgAdmin) {
    return null
  }

  return !currentOrgState ? (
    <div />
  ) : (
    <Box pt={3}>
      {(currentOrgState.profileError || currentOrgState.profileSuccess) && (
        <Alert
          sx={{ mb: 2 }}
          severity={currentOrgState.profileSuccess ? 'success' : 'error'}
          data-qa={`org-profile-save-${
            currentOrgState.profileSuccess ? 'success' : 'error'
          }`}
          id='org-save-message'
        >
          {currentOrgState.profileMsg}
        </Alert>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4} lg={4}>
          <Card
            sx={{
              py: {
                xs: 1,
                md: 4,
              },
              px: {
                xs: 1,
                md: 3,
              },
            }}
            variant='outlined'
          >
            <Box>
              <HaloAvatar
                caption={
                  <ImageUploader
                    name='logo'
                    image={currentOrgState.logo}
                    onSave={async (img: string) => {
                      currentOrgState.logo = img
                      if (currentOrgState._id) {
                        await currentOrgState.saveOrgProfile(currentOrgState)
                      }
                    }}
                    showAvatar={false}
                  />
                }
                image={currentOrgState.logo}
              />
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{ mb: 3 }}
            spacing={2}
          >
            <TextField
              data-qa='org-profile-input-name'
              fullWidth
              label='Name'
              placeholder='Name'
              required
              onBlur={handleBlur}
              onChange={handleChange}
              name='legal_business_name'
              value={currentOrgState.legal_business_name}
              error={Boolean(
                isFieldTouched('legal_business_name') &&
                  getError('legal_business_name')
              )}
              helperText={
                isFieldTouched('legal_business_name') &&
                getError('legal_business_name')
              }
            />
            <TextField
              fullWidth
              data-qa='org-profile-input-phone'
              label='Phone'
              name='phone'
              placeholder='ex.4679876535'
              required
              value={currentOrgState.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(isFieldTouched('phone') && getError('phone'))}
              helperText={isFieldTouched('phone') && getError('phone')}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            sx={{ mb: 3 }}
            spacing={2}
          >
            <MaskedInput
              dataQa='org-profile-input-tax-id'
              label='Tax ID'
              mask='taxId'
              name='tax_id'
              value={currentOrgState.tax_id}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(isFieldTouched('tax_id') && getError('tax_id'))}
              helperText={isFieldTouched('tax_id') && getError('tax_id')}
            />
            <TextField
              data-qa='org-profile-input-funds'
              fullWidth
              label='Source of Funds'
              name='source_of_funds'
              placeholder='Source of Funds'
              value={currentOrgState.source_of_funds}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(
                isFieldTouched('source_of_funds') && getError('source_of_funds')
              )}
              helperText={
                isFieldTouched('source_of_funds') && getError('source_of_funds')
              }
            />
          </Stack>
          <Grid item xs={12}>
            <AddressEditor />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        <AdminOrganizationProfileControls />
      </Box>
    </Box>
  )
}

export default observer(OrgProfile)
