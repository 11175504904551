import React, { FC, Fragment } from 'react'
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import { observer } from 'mobx-react'

import useFormContext from '../../../../../components/Form/FormContext'
import { useRootStore } from '../../../../../services/RootStoreContext'

const OrgToggles: FC = (): React.JSX.Element => {
  const { orgStore } = useRootStore()
  // currentOrg is the Host of this new client's organization
  // orgData is the client's organization.
  const { currentOrg } = orgStore

  const { setFieldValue, model: orgData } = useFormContext()

  // How do you edit an Organization Host's config information?
  //  specifically showInvestmentInvoicing
  // the Host or parent of this orgData
  //  must have hidInvestments set to false.
  // Therefore, any Host organization that has hideInvestments
  //  set to true, cannot set a Client/Portal showInvoicing to true.
  // Seems to also be contingent on GlobalGroup config
  //  Edit Global Group,
  //    Investment Permissions => View Invoices
  //       this.groups.some(g => {
  //         if (g.isGlobalGroup && g.members.includes(userId)) {
  //           const gg = this.hostGlobalGroups.find(hgg => hgg.id === g.id)
  //           return gg && gg.allowInvestmentInvoicing
  //         }
  const getInvestmentToggles = () => {
    // hideInvestments
    //  menu navItems
    //    Investments/Reports
    //    Investments/Invoice
    if (!currentOrg.hideInvestments) {
      // the OrgData's Host Org allows Investments NavSection
      return (
        <Fragment>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormControlLabel
              label='Show Investments'
              control={
                <Checkbox
                  checked={!orgData.hideInvestments}
                  onChange={() => {
                    setFieldValue('hideInvestments', !orgData.hideInvestments)
                  }}
                />
              }
            />
          </Grid>
          {!orgData.hideInvestments && (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show Invoicing'
                control={
                  <Checkbox
                    checked={
                      orgData.config && orgData.config.showInvestmentInvoicing
                    }
                    onChange={() => {
                      setFieldValue(
                        'config.showInvestmentInvoicing',
                        !orgData.config.showInvestmentInvoicing
                      )
                    }}
                  />
                }
              />
            </Grid>
          )}
        </Fragment>
      )
    }
    return null
  }

  return (
    <Container maxWidth='lg'>
      <Stack spacing={3}>
        <Box>
          <Divider>
            <Typography>Resources</Typography>
          </Divider>
          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show Analytics'
                control={
                  <Checkbox
                    checked={orgData.showAnalytics}
                    onClick={() => {
                      setFieldValue('showAnalytics', !orgData.showAnalytics)
                    }}
                  />
                }
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show Events'
                control={
                  <Checkbox
                    checked={!orgData.hideEvents}
                    onChange={() => {
                      setFieldValue('hideEvents', !orgData.hideEvents)
                    }}
                  />
                }
              />
            </Grid>
            {getInvestmentToggles()}
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show Messages'
                control={
                  <Checkbox
                    checked={!orgData.hideMessages}
                    onChange={() => {
                      setFieldValue('hideMessages', !orgData.hideMessages)
                    }}
                  />
                }
              />
            </Grid>
            {currentOrg.showOutreach && (
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormControlLabel
                  label='Show Outreach'
                  control={
                    <Checkbox
                      checked={orgData.showOutreach}
                      onChange={() => {
                        setFieldValue('showOutreach', !orgData.showOutreach)
                      }}
                    />
                  }
                />
              </Grid>
            )}
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show Polls'
                control={
                  <Checkbox
                    checked={!orgData.hidePolls}
                    onChange={() => {
                      setFieldValue('hidePolls', !orgData.hidePolls)
                    }}
                  />
                }
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <FormControlLabel
                label='Show News &amp; Updates'
                control={
                  <Checkbox
                    checked={!orgData.hidePosts}
                    onChange={() => {
                      setFieldValue('hidePosts', !orgData.hidePosts)
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Divider>
            <Typography>Members</Typography>
          </Divider>
          <FormControlLabel
            label='Allow members to see each other'
            control={
              <Checkbox
                checked={!orgData.hideMembersByHost}
                onChange={() => {
                  setFieldValue('hideMembersByHost', !orgData.hideMembersByHost)
                }}
              />
            }
          />
        </Box>
      </Stack>
    </Container>
  )
}

export default observer(OrgToggles)
