/* eslint-disable react/require-default-props */
import React, { FC, Fragment, useMemo, useState } from 'react'
import { Link, Tooltip } from '@mui/material'
import { observer } from 'mobx-react'

import { CreateButton, SaveButton } from '../../../components/Buttons'
import {
  Form,
  FormDrawer,
  FormDrawerContent,
  FormDrawerHeader,
  HandleSubmitProps,
  MobxForm,
  MobxFormBag,
} from '../../../components/Form'
import { EditTrigger } from '../../../components/ActionTriggers'
import OrgProfile from '../../Settings/OrgProfile/OrgProfile'
import { useRootStore } from '../../../services/RootStoreContext'
import Organization from '../../../Model/Organization'
import OrgProfileForm from '../../../Model/Forms/OrgProfileForm'

const ClientDrawer: FC<{
  org?: Organization
  type: 'create' | 'edit' | 'edit-link'
  fetchOrgs?: () => void
  legalBusinessName?: string
}> = ({ org, type, fetchOrgs, legalBusinessName }): React.JSX.Element => {
  const rootStore = useRootStore()
  const { addNotificationItem, orgStore } = rootStore
  const {
    currentOrg,
    getAvailableOrgs,
    syncCRMAccounts,
    syncInvestmentAccounts,
  } = orgStore
  const [isOpen, setIsOpen] = useState(false)

  const [orgData, setOrgData] = useState<OrgProfileForm | null>(null)

  const initializeFormModel = (): OrgProfileForm => {
    return new OrgProfileForm(
      rootStore,
      org?.data ||
        (currentOrg.isHost
          ? {
              hideInvestments: currentOrg.hideInvestments,
              host: currentOrg._id,
            }
          : {})
    )
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  // new refactor
  const handleOpen = () => {
    if (!orgData) {
      const formModel = initializeFormModel()
      setOrgData(formModel)
      formModel.getMembers().finally(() => setIsOpen(true))
    } else {
      orgData.getMembers().finally(() => setIsOpen(true))
    }
  }
  const getTriggerElement = () => {
    switch (type) {
      case 'create':
        return (
          <Tooltip title='Add Client'>
            <CreateButton data-qa='create-client-button' onClick={handleOpen} />
          </Tooltip>
        )
      case 'edit-link':
        return <Link onClick={handleOpen}>Click Here</Link>
      default:
        return (
          <EditTrigger
            content={`Edit ${legalBusinessName}`}
            onClick={handleOpen}
          />
        )
    }
  }
  // new refactor
  const triggerElement = useMemo(
    () => getTriggerElement(),
    [org?.data?.legal_business_name]
  )

  const handleSave = async ({
    model: formModel,
    actions: { setIsSubmitting },
  }: HandleSubmitProps) => {
    if (!orgData) return

    setIsSubmitting(true)
    await formModel.submit()

    const isNew = !formModel._id
    const updatedOrg = await orgData.saveOrgProfile(formModel)

    if (currentOrg.isHost && formModel._id !== currentOrg._id) {
      if (isNew) {
        currentOrg.relationshipManagement.assignments.replace(
          currentOrg.relationshipManagement.assignments.map(assignment => {
            if (typeof assignment.org_id === 'undefined') {
              assignment.org_id = updatedOrg._id
            }

            return assignment
          })
        )
      }

      await currentOrg.relationshipManagement.save(false)
      syncInvestmentAccounts(false)
      syncCRMAccounts(false)
    }

    setIsSubmitting(false)

    if (!orgData.profileError) {
      addNotificationItem({
        message: `Successfully ${type === 'create' ? 'added' : 'updated'} ${
          orgData.legal_business_name
        }`,
        success: true,
      })
      handleClose()
      if (fetchOrgs) {
        fetchOrgs()
      } else {
        getAvailableOrgs()
      }
    }
  }

  return (
    <Fragment>
      {triggerElement}
      <FormDrawer isOpen={isOpen} onClose={handleClose}>
        <MobxForm model={orgData} handleSubmit={handleSave}>
          {({
            submitDisabled,
            onSubmit,
            isSubmitting,
            isValid,
            model: orgModel,
          }: MobxFormBag) => {
            const extendDisabled =
              orgModel.hasRelationshipAssignmentUpdated && isValid

            return (
              <Form>
                <FormDrawerHeader
                  onClose={handleClose}
                  title={
                    type === 'create'
                      ? 'Create Client'
                      : `Edit ${orgData.legal_business_name}`
                  }
                >
                  <SaveButton
                    disabled={!extendDisabled && submitDisabled}
                    isSaving={isSubmitting}
                    onClick={async () => {
                      onSubmit()
                    }}
                  />
                </FormDrawerHeader>
                <FormDrawerContent>
                  <OrgProfile />
                </FormDrawerContent>
              </Form>
            )
          }}
        </MobxForm>
      </FormDrawer>
    </Fragment>
  )
}

export default observer(ClientDrawer)
