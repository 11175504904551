import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Stack, Box, Tooltip } from '@mui/material'
import {
  GridActionsColDef,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowSelectionModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import { createViewModel } from 'mobx-utils'

import Actions from '../../Actions/Actions'
import ActivityIndicators from '../../ActivityIndicators/ActivityIndicators'
import DataTable from '../../Table/DataTable'
import { GetWrappedIcon } from '../../Icons'
import MHidden from '../../MHidden/MHidden'
import OverflowPopup from '../../OverflowPopup/OverflowPopup'

import DocumentActions from './DocumentActions'

import DocumentsListTableToolbar from './DocumentsListTableToolbar'
import DocumentListTableNoRowsOverlay from './DocumentListTableNoRowsOverlay'

import Documents from '../../../Model/Documents'
import File from '../../../Model/File'
import Folder from '../../../Model/Folder'

import { useRootStore } from '../../../services/RootStoreContext'

const DocumentsListTable: FC<{
  currentDirectory?: Folder | Documents | undefined
  disableBulkActions?: boolean
  documents: Documents
  embedded?: boolean
  isHostOrgDocuments: boolean
  onFileClick?: ((data: { node: File }) => void) | undefined
  onFolderClick?: ((data: { node: Folder }) => void) | undefined
}> = ({
  currentDirectory,
  disableBulkActions,
  documents,
  embedded,
  isHostOrgDocuments,
  onFileClick,
  onFolderClick,
}) => {
  const {
    orgStore: { currentOrg, isHostUser },
    discussionStore: { discussions },
    userStore: user,
  } = useRootStore()
  const { hostName, isOrgAdmin } = currentOrg
  const [currentDirectoryContents, setCurrentDirectoryContents] = useState([])
  const apiRef = useGridApiRef()

  const resetCurrentDirectoryContents = () => {
    if (currentDirectory && currentDirectory.contents) {
      setCurrentDirectoryContents(currentDirectory.contents.slice())
    }
  }

  useEffect(() => {
    if (apiRef?.current?.setQuickFilterValues) {
      apiRef.current.setQuickFilterValues([])
    }

    resetCurrentDirectoryContents()
  }, [currentDirectory])

  useEffect(resetCurrentDirectoryContents, [documents?.saved])

  const rows = useMemo(
    () =>
      currentDirectoryContents.map(node => ({
        ...node,
        id: node.permalink,
        modified:
          node.modified ||
          (node.contents && node.contents.length > 0
            ? node.contents.reduce(
                (acc, piece) =>
                  piece.modified
                    ? piece.modified > acc
                      ? piece.modified
                      : acc
                    : acc,
                0
              )
            : 0),
      })),
    [currentDirectoryContents]
  )

  const handleNodeClick = useCallback((node: File | Folder) => {
    if (!node) {
      return null
    }

    documents.bulkSelectedList = []

    if (node.type === 'folder') {
      return onFolderClick({ node })
    }

    return onFileClick({ node })
  }, [])

  // type     0.05
  // name     0.4
  // modified 0.2 or 0.15
  // actions  0.05
  const columns: (GridColDef | GridActionsColDef)[] = useMemo(() => {
    const headers = [
      {
        field: 'type',
        filterable: false,
        flex: 0.05,
        headerName: 'Type',
        resizable: true,
        renderCell: (params: GridRenderCellParams) => {
          const { id } = params
          const node = currentDirectoryContents.find(
            opt => opt.permalink === id
          )

          if (!node) {
            return null
          }

          const isExportsFolder =
            node.type === 'folder' && node.name === 'Exports'

          if (isExportsFolder) {
            return (
              <Tooltip title='System folder containing exported documents'>
                <GetWrappedIcon
                  color={node.iconColor}
                  name='exportsFolder'
                  onClick={() => handleNodeClick(node)}
                />
              </Tooltip>
            )
          }

          if (node.isGlobalFolder) {
            return (
              <GetWrappedIcon
                color={node.iconColor}
                name='systemFolder'
                onClick={() => handleNodeClick(node)}
              />
            )
          }

          if (node.isHostFolder) {
            return (
              <Tooltip title={`${hostName} Folder`}>
                <GetWrappedIcon
                  color={node.iconColor}
                  name={node.icon}
                  onClick={() => handleNodeClick(node)}
                />
              </Tooltip>
            )
          }

          return (
            <GetWrappedIcon
              color={node.iconColor}
              icon={node.type === 'folder' ? undefined : node.icon}
              name={node.type === 'folder' ? node.icon : undefined}
              onClick={() => handleNodeClick(node)}
            />
          )
        },
        sortable: false,
        type: 'string',
      },
      {
        field: 'name',
        flex: 0.4,
        headerName: 'Name',
        type: 'string',
        renderCell: (params: GridRenderCellParams) => {
          const { id } = params
          const node = currentDirectoryContents.find(
            opt => opt.permalink === id
          )

          if (!node) {
            return null
          }

          return (
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              flexGrow={1}
              sx={{ maxWidth: '99%' }}
            >
              <Box
                onClick={() => handleNodeClick(node)}
                sx={{ cursor: 'pointer', maxWidth: '90%' }}
              >
                <OverflowPopup
                  content={node.name.replace('.html', '')}
                  fullWidth
                  sx={{ cursor: 'pointer' }}
                />
              </Box>
              {!embedded && (
                <MHidden width='smDown'>
                  <Stack
                    alignItems='center'
                    direction='row'
                    justifyContent='space-between'
                    onClick={e => {
                      e.stopPropagation()
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <ActivityIndicators
                      item={createViewModel(node)}
                      normalized={{
                        comments: discussions
                          ? discussions.find(d => d.parent === node.key)
                          : [],
                        disabledComments: node.disableComments,
                      }}
                      category='documents'
                      user={user}
                      currentOrg={currentOrg}
                      owner={node.creator}
                      isDocumentList
                    />
                  </Stack>
                </MHidden>
              )}
            </Stack>
          )
        },
      },
      {
        field: 'modified',
        flex: embedded ? 0.2 : 0.15,
        headerName: 'Last Updated',
        resizable: true,
        renderCell: (params: GridRenderCellParams) => {
          const { row: node } = params
          return format(new Date(node.modified), 'PPpp')
        },
        type: 'string',
      },
    ]

    if (!embedded && currentDirectory?.org_id === currentOrg._id) {
      headers.push({
        field: 'actions',
        flex: 0.05,
        getActions: (params: { id: string }) => {
          const { id } = params
          const node = currentDirectoryContents.find(
            opt => opt.permalink === id
          )

          if (!node || node.org_id !== currentOrg._id) {
            return []
          }

          const nodeView = createViewModel(node)
          const isExportsFolder =
            node.type === 'folder' && node.name === 'Exports'
          const actionsDisabled = isExportsFolder || node.isReadOnly

          if (!embedded && isOrgAdmin && (!actionsDisabled || isHostUser)) {
            return [
              <Actions
                key={`${node.permalink}-actions-container`}
                actions={
                  <DocumentActions
                    node={nodeView}
                    documents={documents}
                    isFromList
                  />
                }
                addShare
                closeOnClick={false}
                item={nodeView}
              />,
            ]
          }

          return []
        },
        headerName: 'Actions',
        hideable: false,
        type: 'actions',
      })
    }

    return headers
  }, [currentDirectoryContents, documents.saved])

  const handleSelectionModelChange = useCallback(
    (selectionModel: GridRowSelectionModel) => {
      documents.bulkSelectedList = currentDirectoryContents.filter(item =>
        selectionModel.includes(item.permalink)
      )
    },
    [currentDirectoryContents]
  )
  return (
    <Box width='100%' pl={1} pr={1}>
      <DataTable
        apiRef={apiRef}
        checkboxSelection={!disableBulkActions}
        columns={columns}
        slots={{
          noRowsOverlay: DocumentListTableNoRowsOverlay,
          toolbar: DocumentsListTableToolbar,
        }}
        slotProps={{
          toolbar: {
            disableBulkActions,
            documents,
          },
        }}
        disableRowSelectionOnClick
        hideFooter
        id={`document-list-dt-${currentOrg._id}`}
        isRowSelectable={(params: GridRowParams) => {
          const { row: node } = params

          if (!node || node.isReadOnly) {
            return false
          }

          if (node.type === 'folder') {
            if (node.name === 'Exports') {
              return false
            }

            if (node.isInherited) {
              return node.path.split('/').length > 2
            }

            return !node.isHostFolder
          }

          return !node.isGlobalFolder
        }}
        loading={documents.isSaving}
        onRowSelectionModelChange={handleSelectionModelChange}
        pagination={false}
        rows={rows}
        sx={{
          height: embedded ? (isHostOrgDocuments ? '60vh' : '45vh') : '65vh',
        }}
      />
    </Box>
  )
}

DocumentsListTable.defaultProps = {
  disableBulkActions: true,
  currentDirectory: undefined,
  onFolderClick: undefined,
  onFileClick: undefined,
  embedded: false,
}

export default observer(DocumentsListTable)
