import React, {
  ChangeEvent,
  FocusEvent,
  FC,
  useRef,
  useState,
  Fragment,
} from 'react'
import {
  Button,
  Box,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  Paper,
  Tooltip,
} from '@mui/material'
import { observer } from 'mobx-react'

import moment from 'moment'
import FileManagerModal from '../FileManagerModal/FileManagerModal'
import OverflowPopup from '../OverflowPopup/OverflowPopup'
import SearchOrgResources from '../SearchOrgResources'
import { Select } from '../Form'
import TabPanel from '../TabPanel/TabPanel'

import File from '../../Model/File'
import ModelBase from '../../Model/ModelBase'

import { useRootStore } from '../../services/RootStoreContext'

// Callers:
//  UI => Message Edit Window Attach Link button
//   web/src/components
//    EditorAttachmentForm
//      EditorAttachmentItems
//  UI => top navbar select star button
//    web/src/layouts/dashboard/TopNav/index
//      web/src/components/Starred/StarDrawer
const SelectOrgResource: FC<{
  getSelectOptionIcon?: () => React.JSX.Element
  isForStars?: boolean
  isForTodos?: boolean
  item?: ModelBase
  onTabChange?: (newVal: number) => void
  onSelect: (obj: ModelBase) => void
  onUrlBlur?: (event: FocusEvent) => void
  onUrlChange: (event: ChangeEvent<HTMLInputElement>) => void
  onUrlTitleBlur?: (event: FocusEvent) => void
  onUrlTitleChange: (event: ChangeEvent<HTMLInputElement>) => void
  urlSaveButton?: React.JSX.Element
}> = ({
  getSelectOptionIcon,
  isForStars,
  isForTodos,
  item,
  onTabChange,
  onSelect,
  onUrlBlur,
  onUrlChange,
  onUrlTitleBlur,
  onUrlTitleChange,
  urlSaveButton,
}): React.JSX.Element => {
  const {
    allItemsMostRecent,
    documentStore: { allFlattenedDocuments, allFlattenedFolders },
    starStore,
    orgStore: { currentOrg },
    userStore,
  } = useRootStore()
  const orgResourceRef = useRef(null)
  const [type, setType] = useState('')
  const [activeTab, setActiveTab] = useState<number>(0)
  const [fileManagerModalVisible, toggleFileManagerModalVisible] =
    useState(false)
  const [documentName, setDocumentName] = useState<string | undefined>()
  const [selectedResource, setSelectedResource] = useState<ModelBase>()
  const currentUser = currentOrg.getMember(userStore._id)
  const types = [
    { key: 'document', value: 'document', label: 'Documents' },
    { key: 'events', value: 'events', label: 'Events' },
    { key: 'orgForms', value: 'orgForms', label: 'Forms' },
    { key: 'todos', value: 'todos', label: 'Todos' },
    { key: 'messages', value: 'discussions', label: 'Messages' },
    { key: 'content', value: 'content', label: 'News & Updates' },
    { key: 'polls', value: 'polls', label: 'Poll' },
  ]
    // Don't show option if there are not any "sub" options
    .filter(
      t =>
        t.value === 'document' ||
        allItemsMostRecent.some(resource => resource.category === t.value)
    )
    .filter(t => {
      // todos can only be attached to messages by host users
      if (
        t.value !== 'todos' ||
        (t.value === 'todos' &&
          currentUser.isHostUser &&
          item?.modelCollection === 'discussions')
      ) {
        return true
      }
      return false
    })

  const getItems = (): ModelBase[] => {
    const initialItems = (
      type && type !== 'url'
        ? type === 'document'
          ? documentName
            ? [
                allFlattenedDocuments.find(
                  (doc: File) => doc.key === documentName
                ),
              ]
            : []
          : allItemsMostRecent.filter(
              (resource: ModelBase) =>
                resource.category === type && !resource.isArchived
            )
        : []
    ).filter(resource => {
      if (!item || !item?.links) {
        return true
      }
      return (
        !item.links.some(
          l =>
            !['document', 'url'].includes(l.type) &&
            l.id &&
            l.id.length > 0 &&
            l.id === (resource?.getNotifyId() || resource?._id)
        ) &&
        (resource?.getNotifyId() || resource?._id) !==
          (item?.getNotifyId() || item?._id)
      )
    })

    if (isForStars) {
      return initialItems.filter(
        resource =>
          resource.modelCollection !== 'todos' &&
          !starStore.items.some(star => star.uid === resource.getNotifyId())
      )
    }

    if (isForTodos && item?.org_id) {
      return initialItems.filter(resource => {
        return (
          resource.modelCollection !== 'todos' ||
          resource.org_id === item.org_id
        )
      })
    }
    return initialItems
  }

  const items = getItems()
  // {
  //   createdDate && (
  //     <Typography variant='caption' sx={{ mr: 1 }}>
  //       {createdDate}
  //     </Typography>
  //   )
  // }
  const getLabelFragment = (resource: ModelBase) => {
    return (
      <Tooltip
        title={
          <Typography variant='caption' sx={{ mr: 1 }}>
            Last Updated: {moment(resource.listUpdatedAt).format('lll')}
          </Typography>
        }
      >
        <span>{resource.listTitle}</span>
      </Tooltip>
    )
  }
  const selectTypeOptions = items.map(option => ({
    key: option.getNotifyId(),
    // Add text field without the html for Search Filtering
    text: option.listTitle,
    label: getLabelFragment(option),
    value: option.getNotifyId(),
  }))

  const panels = [
    <TabPanel index={0} value={activeTab} key='select-org-resource-resource'>
      <SearchOrgResources
        allItems={items}
        flattenedDocuments={allFlattenedDocuments}
        flattenedFolders={allFlattenedFolders}
        fullWidth
        icon={getSelectOptionIcon()}
        onSelect={resource => {
          setType(
            resource.modelCollection === 'organization-forms'
              ? 'orgForms'
              : resource.modelCollection || 'document'
          )
          setSelectedResource(resource)

          if (resource.modelCollection === 'documents') {
            setDocumentName(resource.name)
          }

          if (onSelect) {
            onSelect(resource)
          }
        }}
        onFocus={() => {
          if (orgResourceRef && orgResourceRef.current) {
            // Give it just a little time to become visible
            setTimeout(() => {
              orgResourceRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
              })
            }, 3)
          }
        }}
      />
      <Box pt={3} pb={3}>
        <Divider>
          <Typography>Or</Typography>
        </Divider>
      </Box>
      <FileManagerModal
        visible={fileManagerModalVisible}
        toggleVisible={toggleFileManagerModalVisible}
        onFileSelect={(_path: string, file: File) => {
          if (onSelect) {
            onSelect(file)
          }

          setDocumentName(file.name)
        }}
      />
      <Grid container spacing={1}>
        <Grid item sm={12} md={6}>
          <Select
            label='Type'
            defaultValue=''
            value={type || ''}
            options={types}
            onChange={(e, { value }) => {
              if (documentName && value !== 'document') {
                setDocumentName(undefined)
              }
              setType(value)
            }}
          />
        </Grid>
        <Grid item sm={12} md={6}>
          {type === 'document' ? (
            <Box
              sx={{
                alignItems: 'center',
                display: 'inline-flex',
                height: '100%',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Button onClick={() => toggleFileManagerModalVisible(true)}>
                {documentName ? (
                  <OverflowPopup content={documentName} />
                ) : (
                  'Select Document'
                )}
              </Button>
            </Box>
          ) : (
            <Select
              label={type ? 'Item' : 'Select type'}
              onChange={(_e, { value }) => {
                const actualItem =
                  type !== 'documents'
                    ? allItemsMostRecent.find(
                        resource => resource.getNotifyId() === value
                      )
                    : items.find(resource => resource.getNotifyId() === value)

                setSelectedResource(actualItem)

                if (type === 'documents' && value === 'documents') {
                  toggleFileManagerModalVisible(true)
                } else if (onSelect) {
                  onSelect(actualItem)
                }
              }}
              options={selectTypeOptions}
              defaultValue=''
              value={
                type === 'documents'
                  ? documentName
                  : selectedResource?.getNotifyId() || ''
              }
            />
          )}
        </Grid>
      </Grid>
    </TabPanel>,
    <TabPanel index={1} value={activeTab} key='select-org-resource-url'>
      <TextField
        label='Title:'
        onChange={onUrlTitleChange}
        onBlur={onUrlTitleBlur}
        fullWidth
        required
      />
      <TextField
        label='URL:'
        sx={{ mt: 2 }}
        onChange={onUrlChange}
        onBlur={onUrlBlur}
        fullWidth
        required
      />
      {urlSaveButton}
    </TabPanel>,
  ]

  return (
    <div className='select-org-resource'>
      <Tabs
        textColor='inherit'
        value={activeTab}
        onChange={(e, newVal) => {
          setActiveTab(newVal)
          onTabChange(newVal)
        }}
      >
        <Tab
          data-qa='qa-search-select-menu-item'
          key='search-select-menu-item'
          label={
            <Typography className='hide-mobile'>Search / Select</Typography>
          }
        />
        <Tab
          data-qa='qa-link-menu-item'
          key='link-menu-item'
          label={<Typography className='hide-mobile'>Link</Typography>}
        />
      </Tabs>
      <Paper
        sx={{ p: 2, borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
        variant='outlined'
      >
        {panels}
      </Paper>
    </div>
  )
}

SelectOrgResource.defaultProps = {
  getSelectOptionIcon: () => null,
  isForStars: false,
  isForTodos: false,
  item: undefined,
  onTabChange: () => undefined,
  onUrlBlur: () => undefined,
  onUrlTitleBlur: () => undefined,
  urlSaveButton: null,
}

export default observer(SelectOrgResource)
